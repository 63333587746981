.banner-header{
    height: 400px;
    background-position: bottom;
    background-image: url('../../img/arfert-banner-header.jpg');
    background-size: cover;
    margin-top: 0px;
    @media (min-width: 992px) {
        margin-top: -30px;
    }
    h1{
        color: white;
        font-weight: 600;
        padding-top: 9rem;
        display: block;
        font-size: 1.4rem;
        text-align: center;
        text-shadow: 1px 1px 3px #000000a6;
        &::after{
            content: " ";
            height: 3px;
            background: $primary;
            width: 100%;
            display: block;
        }
        @media (min-width: 540px) {
            font-size: calc(1.375rem + 1.5vw);
            display: inline-block;
            text-align: left;
        }
        @media (min-width: 992px) {
            padding-top: 6rem;
            font-weight: 300;
        }
    }
    h2{
        color: white;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
        text-shadow: 1px 1px 3px #000000a6;
        @media (min-width: 540px) {
            font-size: calc(1.325rem + .9vw);
            margin-left: 2rem;
            text-align: left;
        }
        @media (min-width: 992px) {
            font-weight: 300;
        }
    }
}
.linea-banner-header{
    overflow: hidden;
    height: 50px;
    @media (min-width: 768px) {
        height: 80px;
    }
    img{
        height: 50px;
        object-fit: cover;
        mix-blend-mode: multiply;
        @media (min-width: 768px) {
            height: 80px;
        }
        @media (min-width: 1200px) {
            width: 100%;
        }
    }
}

