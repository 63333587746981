.datos-pequenos{
    background-color: #F9FAF9;
    padding: 3rem 0;
    text-align: center;
    h6{
        color: $dark;
    }
    img{
        width: 1rem;
        height: 1rem;
        opacity: .5;
    }
}
.mapa{

    object-fit: cover;
    min-height: 300px;

}