.banner-inicial{
    margin-top: 60px;
    img{
        object-fit: cover;
        height: 250px;
        @media (min-width: 576px) {
            height: 450px;
        }
        @media (min-width: 992px) { 
            height: 100%;
        }
    }
    @media (min-width: 992px) { 
        margin-top: -30px;
    }
}