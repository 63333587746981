.linea-tiempo{
    background-image: url('../../img/bg-historia.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 4rem;
    h2{
        color: $dark;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 1rem;
    }
    .swiper-button-next::after, .swiper-button-prev::after{
        text-transform: none !important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        line-height: 1;
        color: white;
        font-size: 1rem;
    }
    .info-linea{

        .swiper-slide{
            div{
                background-image: linear-gradient(to bottom, rgb(255, 255, 255) , rgba(255, 255, 255, 0.5));
                padding: 1rem;
                height: 300px;
                margin-bottom: 1rem;
                @media (min-width: 476px) {
                    height: 385px;
                }
                @media (min-width: 768px) {
                    height: 345px;
                }
                @media (min-width: 992px) {
                    height: 370px;
                }
                @media (min-width: 1200px) {
                    height: 310px;
                }
                @media (min-width: 1500px) {
                    height: 245px;
                }
                p{
                    font-size: .9rem;
                    text-align: justify;
                }
                h6{
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    color: $dark;
                }
            }
            img{
                width: 30px;
                height: 30px;
                margin: 0 auto;
                display: block;
            }
        }
    }

    .control-fechas{
        background: $primary;
        border-radius: 20px;
        max-height: 50px;
        top: -14px;
        .swiper-slide{
            text-align: center;
            padding-top: 17px;
            color: white;
        }
    }
}

