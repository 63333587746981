.mision-vision{
    padding: 1rem;
    h6{
        font-size: .9rem;
        font-weight: 600;
    }
    h3{
        font-size: 2rem;
        color: $dark;
        font-weight: 600;
        margin-bottom: 0rem;
        line-height: .8;
    }
    h5{
        font-size: 1.5rem;
        color: $dark;
        font-weight: 300;
    }
    .nav-link{
        color:$dark;
        font-size: .8rem;
        font-weight: 600;
        border-radius: 0;
        border-bottom: solid 3px #D7D7D7;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
        color: $dark;
        background-color: #FFF;
        border-bottom: solid 3px $primary;
    }
    p{
        text-align: justify;
    }
}