.formulario{
    padding-top: 2rem;
    h2{
        color: $dark;
    }
    .linea{
        border-left:solid $body-color 1px;
        p{
            margin-bottom: 0;
        }
    }
    .encabezado{
        margin-bottom: 2rem;
    }
}

.formulario7{
    margin-bottom: 2rem;
    .form-control{
    border: none;
    border-bottom: 2px solid #ced4da;
    border-radius: 0;
    }
    .wpcf7{
        margin-left: -1rem;
        margin-right: -1rem;
    }
    textarea{
        height: 110px;
    }
    .wpcf7-submit{
        display: inline-block;
        font-weight: 400;
        color: #777;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.2;
        border-radius: .25rem;
        transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
        color: #ffffff;
        background-color: #97BF00;
        border-color: #97BF00;
    }
}