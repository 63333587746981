.tienda-arfert{

  .fichas-tienda{
    display: flex;
    justify-content: start;
    align-items: flex-end;
    h6{
      display: inline-block;
      color: $dark;
    }
  }
  .ficha-tecnica{
    margin-right: 1rem;
  
   }
  .ficha-tecnica img{
   width: 3.5rem;
   vertical-align: bottom;
  }
  .variations .label{
    display: none;
  }
  .ficha-seguridad img{
    width: 3.5rem;
    vertical-align: bottom;
  }
  
  .adicionales{
    hr{
      border-bottom: 2px solid grey;
    }
    .verde{
      border-bottom: 2px solid $primary;
    }
    h5{
      color: $dark;
    }
  }




  .single_variation_wrap{
    display: none !important;
  }
  
  .woocommerce div.product form.cart{
    margin-bottom: 0 !important;
  }
  h3{
    color: #5a301e;
    font-size: 1.3rem;
  }
  .page-title{
    display: none;
  }
  .woocommerce-notices-wrapper{
    display: none;
  }
  .woocommerce-result-count{
    display: none;
  }
  .woocommerce-ordering{
    display: none;
  }
  .price{
    display: none !important;
  }
  .product_type_variable{
    display: none !important;
  }
  .woocommerce-loop-product__title{
    text-align: center;
    color: $dark !important;
  }
  .woocommerce-Price-amount{
    display:none;
  }
  .jil-widget{
    button{
      display: inline-block;
      font-weight: 400;
      color: #777;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      user-select: none;
      background-color: transparent;
      border: 1px solid transparent;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.2;
      border-radius: .25rem;
      transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
      color: #ffffff;
      background-color: #97BF00;
      border-color: #97BF00;
    }
    #woocommerce-product-search-field-0{
      display: inline-block;
      width: 65%;
      height: calc(1.2em + .75rem + 2px);
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
      vertical-align: bottom;
      
    }
    .woocommerce-product-search{
      margin-bottom: 1rem;
    }
  }
  .product_title{
    color: $dark;
    margin-bottom: 0;
  }
  .woocommerce-product-details__short-description{
    
    h3{
      margin-bottom:0;
      color: $dark;
    }
    h4{
      color: $primary;
    }
  }
  .variable-items-wrapper .wvs-has-image-tooltip::after, .variable-items-wrapper [data-wvstooltip]::after {
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #000;
    border-top: 5px solid rgba(51,51,51,.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }

  // .button-variable-item{
  //   box-shadow: 0 0 0 2px $primary !important;
  //   .variable-item-span{
  //     color: $primary !important;
  //     font-weight: 600;
  //   }
  // }
  .related{
    h2{
      color: $dark;
    }
  }
  .logo-hr{
    display: block;
    position: relative;
    margin-top: -60px;
    width: 100%;
    .logo-productos{
      width: 5rem;
      float: right;
      position: relative;
      bottom: 5.5rem;
      display: block;
      @media (min-width: 992px) {
        width: 8rem;
        bottom: 8rem;
      }
    }
    hr{
      width: 100%;
    }
  }
  .adicionales{
    tbody{
      border: solid 1px #dee2e6;
    }
  }
}
.mwm_whatsapp {
  background: #2ea844 !important;
}

