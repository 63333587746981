// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Override Bootstrap here
//

// Toggle global options
// $enable-gradients: true;
// $enable-shadows: true;

// // Customize some defaults
// $body-color: purple;
// $body-bg: #f5f5f5;

@import "./colores.scss";


$navbar-dark-active-color: $primary;
// cambiar fuentes por defecto bootstrap
$enable-responsive-font-sizes: true;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&family=Roboto&display=swap');
$font-family-base: 'Roboto', sans-serif;
$body-color:#777;
$line-height-base: 1.2;

//
// Bring in Bootstrap
//

// @import "../node_modules/bootstrap/scss/bootstrap";

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/input-group";
@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
// @import "../node_modules/bootstrap/scss/card";
// @import "../node_modules/bootstrap/scss/breadcrumb";
// @import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
// @import "../node_modules/bootstrap/scss/jumbotron";
// @import "../node_modules/bootstrap/scss/alert";
// @import "../node_modules/bootstrap/scss/progress";
// @import "../node_modules/bootstrap/scss/media";
@import "../node_modules/bootstrap/scss/list-group";
// @import "../node_modules/bootstrap/scss/close";
// @import "../node_modules/bootstrap/scss/toasts";
// @import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
// @import "../node_modules/bootstrap/scss/carousel";
// @import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/print";


//
// Custom styles
//

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.btn{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

@import "./header.scss";
@import "./vertical-menu.scss";
@import "./footer.scss";
// esclusivos home
@import "./home/banner-inicial.scss";
@import "./home/carusel.scss";
@import "./home/banner-contacto.scss";
@import "./home/porque-arfert.scss";

// esclusivos after
@import "./arfert/linea-tiempo.scss";
@import "./arfert/mision-vision.scss";
@import "./arfert/banner-header.scss";

// esclusivos plan
@import "./plan/planes.scss";

// esclusivos contacto
@import "./contacto/formulario.scss";
@import "./contacto/datos.scss";

// esclusivos contacto
@import "./woocomerce/woocomerce.scss";