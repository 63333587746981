$dark:#5A301E;
$danger: #683729;
$primary: #97BF00;
$verde:#00983b;
$violeta:#900081;
$rojo:#c9022d;
$azul:#0176bf;
$amarillo:#f4e501;

.verde{
 fill: $verde;
 color: $verde;
}
.violeta{
    fill: $violeta;
    color: $violeta;
}
.rojo{
    fill: $rojo;
    color: $rojo;
}
.azul{
    fill: $azul;
    color: $azul;
}
.amarillo{
    fill: $amarillo;
    color: $amarillo;
}
:root {
    --swiper-theme-color: $primary;
    --swiper-navigation-size: 44px;
}
.swiper-pagination-bullet-active {
    opacity: 1;
    background: $dark;
}