.menu-principal{
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.bi-three-dots-vertical{
  fill: $primary;
  width: 30px;
  height: 30px;
}

.nav-bg-xbootstrap {
  display: none;
  width: 100%;
  height: 60px;
  margin: 0;
  position: relative;
  top: 0px;
  left: 0px;
  background: $light;
  padding: 0px 15px;
}

.navbar-xbootstrap span{
  height: 2px;
  background:$dark;
  margin: 7px;
  width: 30px;
  display: block;
}

.movil-logo-header{
  width: 60px;
}

.jil-compartir{
  min-width: 5rem;
  transform: translate3d(-50px, 44px, 0px) !important;
}

.buscador{
  .form-group{
    margin: -8px -16px -8px 0;
  }
  svg{
    fill: white;
    width: 100%;
    height: 100%;
  }
  .cajon{
    width: 0;
    margin: 0;
    padding: 0;
    border: none;
    margin-right: -5px;
    color: white;
    font-size: 18px;
    background: $danger;
    text-align: center;
    vertical-align: middle;
    height: 57px;
    border-radius: 10px 0 0 10px;
    transition: all 1s;
  }
  
  button{
    margin: 0;
    padding: 0.65rem;
    height: 57px;
    width: 70px;
  }
  &:hover .cajon{
    width: 200px;
    
  }
}


@media only screen and (max-width:991px) {  
  .nav-xbootstrap {
    background: #fff;
    width: 280px;
    height: 100%;
    display: block;
    position: fixed;
    left: -280px;
    top: 0px;
    transition: left 0.5s ease;
    margin: 0;
    border: 0;
    border-radius: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    z-index: 1000000;
  }
  .nav-xbootstrap.visible {
    left: 0px;
    transition: left 0.5s ease;
  }
  .nav-bg-xbootstrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    margin: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    background: $light;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    z-index: 10000000;
  }

  .nav-xbootstrap li:first-child a { 
      border-radius: 0; 
      margin-top: 60px;
    }
  .navbar-nav{
      margin-left: 1rem;
  }
  .cover-bg {
    background: rgba(0,0,0,0.5);
    transition: all 0.5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .buscador{
    margin-top: 1rem;
    .form-group{
      margin: -8px -16px -8px 0;
    }
    svg{
      fill: white;
      width: 100%;
      height: 100%;
    }
    .cajon{
      width: 150px;
      margin: 0;
      padding: 0;
      border: none;
      margin-right: -4px;
      color: #333;
      font-size: 18px;
      background: #fff;
      text-align: center;
      vertical-align: middle;
      height: 40px;
      border-radius: 5px 0 0 5px;
      transition: all 1s;
      margin-left: 1rem;
    }
    button{
      margin: 0;
      padding: 0.25rem;
      height: 40px;
      width: 40px;
    }
    &:hover .cajon{
      width: 150px;
    }
  }
}
    


