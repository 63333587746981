.planes{
    .informacion{
        max-width: 720px;
        margin: 0 auto;
        h5{
            color: $dark;
            margin-top: 3rem;
        }
    }
    .iconos-descargar{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 1.5rem;
        .bi-arrow-down-circle-fill{
            height: 3rem;
            width: 3rem;
            fill: #66B11E;
            margin-right: .25rem;
        }
        img{
            height: 4rem;
            width: 3rem;
            margin-left: .25rem;
        }
    }

}