footer{
    background: $dark;
    color: rgba(255,255,255,0.5);
    text-align: center;
    vertical-align: baseline;
    padding: 1rem 0;
    .container .row div{
        margin-top: .5rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        @media only screen and (min-width:768px) { 
            margin-bottom: .5rem;
        } 
    }
    .logo{
        img{
            width: 5rem;   
        }
    }
    .redes{
        .link-redes{
            display: block;
            margin: 0 0.25rem;
            img{
                width: 2rem;
                height: 2rem;
                filter: invert(99%) sepia(95%) saturate(552%) hue-rotate(202deg) brightness(115%) contrast(100%);
                opacity: .5;
                &:hover{
                    opacity: 1;
                }
            }
        }
    }
    .copy{
        span{
            display: block;
            @media only screen and (min-width:768px) { 
                display: inline;
            } 
        }
        img{
            width: 1rem;
            height: 1rem;
            filter: invert(100%) sepia(4%) saturate(229%) hue-rotate(8deg) brightness(111%) contrast(101%);
            opacity: .5;
        }
        
    }
    .btn-outline-light{
        opacity: .5;
    }
    
}

.radar {
    position: fixed;
    bottom: 21px;
    right: 20px;
    border-radius: 50%;
    border: 10px solid #fff;
    width: 70px;
    height: 70px;

    -webkit-animation: radar 2s infinite;
    animation: radar 2s infinite;
    opacity: 0;
    overflow:hidden;
    z-index: 100000000000;
}

.radar:nth-child(2) {
    animation-delay: .4s;
}

.radar:nth-child(3) {
    animation-delay: .9s;
}

.radar:nth-child(4) {
    animation-delay: .15s;
}

@keyframes radar{
    0%{
        width:70px;
        height:70px;
        border:40px solid green;
        opacity:0
    }50%{
        opacity:.1
    }
    90%{
        width:80px;
        height:80px;
        bottom:16px;
        right:14px;
    }
    90%,100%{
        border:2px
            solid green;
        opacity:0
    }100%{
        width:90px;
        height:90px;
        bottom:11px;
        right:10px;
    }
}
.llamada-whatsapp{
    // filter: invert(51%) sepia(99%) saturate(355%) hue-rotate(50deg) brightness(94%) contrast(94%);
    z-index: 100000000000;
}