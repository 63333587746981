.carusel-home{
  .swiper-container {
    width: 100%;
    height: auto;

  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    position: relative;
    img{
       width: 100%; 
    }
    a{
      color: #777;
    }
    .jil-slider-boton{
      bottom: 180px;
      right: 5px;
      position: absolute;
      display: block;
      z-index: 100;
      width: 150px;
      text-align: left;
      line-height: .9rem;
      font-size: .8rem;
      svg{
        position: relative;
        height: 50px;
        display: block;
        width: 50px;
        top: 16px;
        left: 42px;
      }
    }
  }

}

