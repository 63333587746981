::-webkit-scrollbar-thumb {
    background-color: #F90;	
    background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, .2) 25%,
                                            transparent 25%,
                                            transparent 50%,
                                            rgba(255, 255, 255, .2) 50%,
                                            rgba(255, 255, 255, .2) 75%,
                                            transparent 75%,
                                            transparent)
}

.porque-arfert{
    text-align: center;
    background-color: #EAECEB;
    img{
        object-fit: cover;
        object-position: 31% 0;
        height: 280px;
        @media (min-width: 768px) {
            height: auto;
        }
    }
    h2{
        color: $dark;
        border-bottom: 2px $primary solid;
        display: inline-block;
        margin: 0 auto;
        margin-bottom: 1rem;
        span{
            font-weight: 300;
        }
        @media (min-width: 768px) {
            margin-bottom: 0rem;;
         }
    }
    h3{
        color: $dark;
        font-weight: 300;
        @media (min-width: 768px) {
            font-size: 1.2rem;
            margin-bottom: 0rem;
        }
    }
    .tarjetas{
        padding: 1rem;
       
        .tarjeta{
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            scrollbar-color: red yellow;
            
            @media (min-width: 768px) {
                display: block;
                overflow-y: scroll;
                height: 240px;
                overflow-x: none;
             }
            @media (min-width: 768px) {
                overflow-x: none;
                height: auto;
                overflow: hidden;
            }
            .tarjetica{
                min-width: 100%;
                padding: 1rem;
                background: white;
                border-radius: .5rem;
                margin-bottom: 1.5rem;
                margin-right: 1rem;
                @media (min-width: 768px) {
                   display: flex;
                   padding: 1rem;
                   margin-right: 0;
                   margin-bottom: .5rem;
                }
                .bloque1 img{
                    height: 7rem;
                    margin: 0 auto;
                    display: block;
                    margin-bottom: 1rem;
                    @media (min-width: 768px) {
                        height: 4rem;
                        margin-bottom: 0rem;
                    }
                    
                }
                .bloque2{
                    @media (min-width: 768px) {
                        text-align: left;
                        margin-left: 1rem;
                    }
                    p{
                        @media (min-width: 768px) {
                            font-size: .8rem;
                            margin-bottom: 0;
                            text-align: justify;
                        }
                    }
                }
            }
        }
        
    }
    @media (min-width: 768px) {
        height: 400px;
        background: url(../../img/banner-brochure.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 90% 0;
    }
    @media (min-width: 992px) {
        height: 450px;
        background-position: 90% -0;
    }
    @media (min-width: 1200px) {
        height: 400px;
        background-position: bottom;
        background: url(../../img/banner-brochure.jpg), #eaeceb;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: 30%;
        background-position-x: bottom;
    }
    @media (min-width: 1200px) {
        height: 450px;
        background-position: bottom;
        background: url(../../img/banner-brochure.jpg), #eaeceb;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: 30%;
        background-position-y: bottom;
    }


}
/* width */
.tarjeta::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  /* Track */
  .tarjeta::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #777; 
  }
   
  /* Handle */
  .tarjeta::-webkit-scrollbar-thumb {
    background: $primary; 
    border-radius: 10px;
  }
 
  