.banner-contacto{
        img{
            object-fit: cover;
            object-position: 65% 0;
            height: 300px;
            @media (min-width: 768px) {
                height: auto;
            }
        }
    
    @media (min-width: 768px) {
        height: 500px;
        background: url(../../img/banner-home3a.jpg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }
    h2{
        color: $dark;
        border-bottom: 2px $primary solid;
        display: inline-block;
    }
    .texto-transparencia{
        background-color: rgba(255,255,255,.5);
        border-radius: 0.5rem;
    }
}