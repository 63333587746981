header{

    .logo{
        width: 100px;
        padding-top: 7px;
    }
    .link-redes:hover{
     text-decoration: none;
    }
    .redes{
        width: 40px;
        opacity: 0.5;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .redes:hover{
        filter: invert(71%) sepia(32%) saturate(4259%) hue-rotate(31deg) brightness(98%) contrast(101%);
        opacity: 1;
    }
    .icon-contacto{
        width: 16px;
        vertical-align: baseline;
    }
    /* https://codepen.io/sosuke/pen/Pjoqqp */
    
    .navbar{
       border-radius: 10px;
       margin-top: 0.5rem;
    }
    
    .telefonos img{
        filter: invert(51%) sepia(99%) saturate(355%) hue-rotate(50deg) brightness(94%) contrast(94%);
    }
    .telefonos{
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        color: black;
    }
    .telefonos a{
        color: black;
    }
}